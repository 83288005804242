module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Lato"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lotus Experience","short_name":"lotus","start_url":"/","background_color":"#4A4EDD","theme_color":"#4A4EDD","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"eee149cfb621291a853ced658cd15567"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
